import './App.css';

function App() {
  return (
    <div className="App">
        <div className={"ml-10 mt-10"}>
            <h3>michael sachen</h3>
            <div className={"mt-16"}>
                <h3><a href="mailto:michaelpsachen@gmail.com">email</a></h3>
            </div>
        </div>
    </div>
  );
}

export default App;
